<template>
  <base-page-fullscreen
    button-label="Volved.nl"
    @click-button="openMainSite"
  >
    <base-card class="box">
      <figure
        v-if="avatarUrl"
        class="avatar"
      >
        <img :src="avatarUrl">
      </figure>

      <p class="error">
        {{ error }}
      </p>

      <form-input
        v-model="form.email"
        name="email"
        placeholder="Email"
        :disabled="isFormDisabled"
        autofocus
        @input="error = null"
      />
      <form-input
        v-model="form.password"
        type="password"
        name="password"
        placeholder="Password"
        :disabled="isFormDisabled"
        @input="error = null"
      />

      <base-group
        class="form__actions"
        align="justify"
      >
        <base-button
          :loading="!!isSubmitting"
          label="Login"
          icon="lock-outline"
          @click="submitForm"
        />
      </base-group>

      <base-group
        class="actions"
        align="center"
        spacing="small"
      >
        <base-button
          href="https://volved.nl/register"
          label="Sign Up"
          link
        />
        or
        <base-button
          href="https://volved.nl/password/reset"
          label="Reset Password"
          link
        />
      </base-group>
    </base-card>
  </base-page-fullscreen>
</template>

<script>
/* Import Mutations */
import LOGIN_MUTATION from '@/graphql/mutations/Login.gql';

/* Import Classes and Helpers */
import Form from '@/utils/Form';
import { onLogin } from '@/utils/apollo';

export default {
  data() {
    return {
      form: new Form({
        email: '',
        password: '',
      }),
      error: null,
      isSubmitting: 0,
      avatarUrl: localStorage.getItem('avatar'),
    };
  },
  computed: {
    isFormDisabled() {
      return !!this.isSubmitting;
    },
    isSubmitDisabled() {
      return this.form.isDefault || this.error;
    },
  },
  methods: {
    async submitForm() {
      if (this.form.email && this.form.password) {
        this.isSubmitting += 1;
        // GraphQL Mutation
        this.$apollo
          .mutate({
            mutation: LOGIN_MUTATION,
            variables: this.form,
          })
          .then(async (data) => {
            const user = data.data.login;
            // Save user ID
            this.$root.$data.userId = user.id;
            localStorage.setItem('user-id', this.$root.$data.userId);
            // Run apollo login actions
            onLogin(this.$apollo.provider.defaultClient);
            // Determine where we need to send the user to
            let { redirect } = this.$route.query;
            if (!user.onboarding_completed) {
              redirect = 'onboarding';
            } else if (!redirect || redirect === 'login') {
              redirect = 'dashboard';
            }
            // Redirect
            this.$router.push({ name: redirect });
          })
          .catch(() => {
            this.error = 'The user credentials were incorrect.';
          })
          .finally(() => {
            this.isSubmitting -= 1;
          });
      } else {
        this.error = 'Please enter your credentials.';
      }
    },
    openMainSite() {
      window.open('https://volved.nl/');
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 280px;
  margin: 0 auto;
}

.avatar {
  margin: 0 0 20px;
  font-size: 0;
  text-align: center;

  img {
    width: 164px;
    height: 164px;
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }
}

.error {
  color: $color-danger;
  text-align: center;
}

.actions {
  margin-top: 32px;
}
</style>
